
import { reactive, ref } from '@vue/reactivity'
import Table from '../components/Table.vue'
import {
  SELECTTIMEMACHINE,
  INSERTTIMEMACHINE,
  UPDATETIMEMACHINE,
  DELETETIMEMACHINE,
  UPDATETIMEMACHINEISISSUE
} from '../api/api'
import { computed, onMounted } from '@vue/runtime-core'
import { Form } from 'ant-design-vue'
import 'dayjs/locale/zh-cn'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import { HistoryOutlined } from '@ant-design/icons-vue'

interface timeMachineFrom {
  id: number
  time_type: number
  name: string
  timestamp: any
}

export default {
  components: {
    Table,
    HistoryOutlined
  },
  setup() {
    onMounted(() => {
      table.value.getTableData()
    })
    const table = ref(null)
    // 类型选择器
    const time_type = [
      { id: 1, name: '回到过去' },
      { id: 2, name: '去往未来' }
    ]
    // 表格字段
    const columns = ref([
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 50,
        align: 'center'
      },
      {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
        width: 300,
        align: 'center'
      },
      {
        title: '时光机类型',
        dataIndex: 'time_type',
        key: 'time_type',
        width: 100,
        align: 'center'
      },
      {
        title: '时间',
        dataIndex: 'timestamp',
        width: 230,
        align: 'center'
      },
      {
        title: '是否展示',
        dataIndex: 'is_issue',
        width: 100,
        align: 'center'
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        width: 100,
        align: 'center'
      },
      {
        title: '操作',
        dataIndex: 'action',
        align: 'center',
        width: 100
      }
    ])
    // 查询表格数据表单
    const selectTimeMachineFrom = reactive({
      name: ''
    })
    // 查询表格数据方法
    const selectTimeMachine = (pageData) => {
      return new Promise((resolve, reject) => {
        SELECTTIMEMACHINE(Object.assign(pageData, selectTimeMachineFrom))
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
    // 弹窗展示状态
    const toolsModal = ref(false)
    // 弹窗标题
    const toolsModalTitle = ref('')
    // 打开弹窗
    const openModel = (scene, record) => {
      toolsModal.value = true
      if (scene === 1) {
        // 新增
        toolsModalTitle.value = '新增时光机'
      } else if (scene === 2) {
        // 编辑
        toolsModalTitle.value = '编辑时光机'
        const { id, time_type, name, timestamp } = record
        timeMachineFrom.id = id
        timeMachineFrom.time_type = time_type
        timeMachineFrom.name = name
        timeselect.value = timestamp
      }
    }
    // 时间选择器时间
    const timeselect = ref(null)

    // 添加时光机表单
    const timeMachineFrom = reactive<timeMachineFrom>({
      id: null,
      time_type: null,
      name: '',
      timestamp: ''
    })
    timeMachineFrom.timestamp = computed({
      get() {
        return timeselect.value == null
          ? ''
          : Date.parse(timeselect.value) / 1000
      },
      set() {
        return true
      }
    })

    // 添加时光机表单验证规则
    const toolsRules = reactive({
      time_type: [
        {
          required: true,
          message: '请选择时光类型',
          trigger: "['change', 'blur']"
        }
      ],
      name: [
        {
          required: true,
          message: '请输入名称',
          trigger: "['change', 'blur']"
        }
      ],
      timestamp: [
        {
          required: true,
          message: '请选择日期',
          trigger: "['change', 'blur']"
        }
      ]
    })

    const useForm = Form.useForm
    const { resetFields, validate, validateInfos } = useForm(
      timeMachineFrom,
      toolsRules
    )
    // 新增时光机方法
    const saveTools = async () => {
      await validate().then(() => {
        if (timeMachineFrom.id == null) {
          // 新增时光机
          INSERTTIMEMACHINE(timeMachineFrom).then((response) => {
            if (response.code === 0) {
              // 关闭弹窗
              closeModel()
            }
          })
        } else if (timeMachineFrom.id != null) {
          // 编辑时光机
          UPDATETIMEMACHINE(timeMachineFrom).then((response) => {
            if (response.code === 0) {
              if (response.code === 0) {
                // 关闭弹窗
                closeModel()
              }
            }
          })
        }
      })
    }

    // 关闭弹窗
    const closeModel = () => {
      timeselect.value = null
      // 重新获取数据
      table.value.getTableData()
      // 重置表单验证
      resetFields()
      // 关闭弹窗
      toolsModal.value = false
    }
    // 删除数据方法
    const deleteTools = (id) => {
      DELETETIMEMACHINE({ id }).then((response) => {
        if (response.code === 0) {
          table.value.getTableData()
        }
      })
    }

    // 更新展示状态
    const toRelease = (issue, id) => {
      if (issue) {
        issue = 1
      } else {
        issue = 0
      }
      UPDATETIMEMACHINEISISSUE({ is_issue: issue, id: id }).then((response) => {
        if (response.code === 0) {
          table.value.getTableData()
        }
      })
    }

    return {
      table,
      time_type,
      columns,
      selectTimeMachineFrom,
      selectTimeMachine,
      toolsModal,
      toolsModalTitle,
      openModel,
      timeMachineFrom,
      saveTools,
      validateInfos,
      closeModel,
      deleteTools,
      locale,
      timeselect,
      toRelease
    }
  }
}
